import React, { useEffect, useState } from 'react'
import { Table, Tag, Layout, Space, Select } from 'antd'

import HouseDetail from '../HouseDetail'
import { get_houses } from '../../services/server'
import './index.css'

const { Content } = Layout


export default function Process() {

    const [isShowDetail, setIsShowDetail] = useState(false)
    const [houseID, setHouseID] = useState('')
    const [total, setTotal] = useState(0)
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [page, setPage] = useState(1)

    useEffect(()=>{
        get_houses({status, page:1}).then(res=>{
            setTotal(res.total)
            var data = res.houses.map(item=>{
                var house = {}
                house.id = item.id
                house.status = item.status
                house.owner = item.user.nick_name
                house.update_time = item.update_time.slice(0,10)
                return house
            })
            setData(data)
        }).catch(err=>{
            console.log(err)
        })
    },[])

    const closeDetail = () => {
        var index
        if(data.length === 0){
            index = page - 1
        }
        get_houses({status, index}).then(res=>{
            setTotal(res.total)
            var data = res.houses.map(item => {
                var house = {}
                house.id = item.id
                house.status = item.status
                house.owner = item.user.nick_name
                house.update_time = item.update_time.slice(0, 10)
                return house
                })
                setData(data)
            })
        setIsShowDetail(false)
    }

    const lookUpHouse = (e) =>{
        setIsShowDetail(true)
        setHouseID(e.target.dataset.house_id)
    }

    const selectChange = (status) => {
        get_houses({ status, page: 1 }).then(res => {
            setTotal(res.total)
            setStatus(status)
            var data = res.houses.map(item => {
                var house = {}
                house.id = item.id
                house.status = item.status
                house.owner = item.user.nick_name
                house.update_time = item.update_time.slice(0, 10)
                return house
            })
            setData(data)
        }).catch(err => {
            console.log(err)
        })
    }

    const dataIndexChange = (page, pageSize) => {
        get_houses({ status, page }).then(res => {
            setTotal(res.total)
            setPage(page)
            var data = res.houses.map(item => {
                var house = {}
                house.id = item.id
                house.status = item.status
                house.owner = item.user.nick_name
                house.update_time = item.update_time.slice(0, 10)
                return house
            })
            setData(data)
        }).catch(err => {
            console.log(err)
        })
    }


    const columns = [
        {
            title: '房子ID',
            dataIndex: 'id',
            key: '1'
        },
        {
            title: '上传时间',
            dataIndex: 'update_time',
            key: '2'
        },
        {
            title: '上传者',
            dataIndex: 'owner'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: '3',
            render: (_, record) => record.status === 0 ? <Tag color='blue'>审核中</Tag> : record.status === 1? <Tag color='green'>已通过</Tag>: <Tag color='red'>未通过</Tag>
        },
        {
            title: '操作',
            key: 'operate',
            key: '4',
            render: (_, record) => (<Space size="middle"><a data-house_id={record.id} onClick={lookUpHouse}>查看</a></Space>)

        }
    ]

  return (
    <div>
        <Layout>
            <div className='h-header'>
                查看选择：
                <Select style={{width:240}} 
                    onChange={selectChange}
                    defaultValue={'审核中'}
                    options={
                        [
                            {
                                value: '0',
                                label: '审核中'
                            },
                            {
                                label: '未通过',
                                value: '2'
                            }
                        ]
                    }
                    ></Select>
            </div>
            <Content>
                <Table
                    dataSource={data}
                    columns={columns}
                    pagination={{
                        total: total,
                        onChange: dataIndexChange,
                        pageSize: 10,
                        showSizeChanger: false
                    }}
                >
                </Table>
            </Content>
        </Layout>
        { isShowDetail? <HouseDetail house_id={houseID} closeDetail={closeDetail} hidden={false}/>:''}
    </div>
  )
}
