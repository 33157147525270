import React, { useEffect, useState } from 'react'
import { Image, Button } from 'antd'

import close from '../../assets/close.svg'
import './index.css'
import { edit_house, get_house_detail } from '../../services/server'

export default function HouseDetail(props) {
    const [house, setHouse] = useState({images:[],videos:[]})
    useEffect(()=>{
        get_house_detail({house_id:props.house_id}).then(res=>{
            setHouse(res.house)
        }).catch(err=>{
            console.log(err)
        })
    },[])
    const closeDetail = () => {
        props.closeDetail()
    }

    const unAccess = () => {
        const data = {
            house_id: props.house_id,
            status: 2
        }
        edit_house(data).then(() => {
            props.closeDetail()
        }) 
    }

    const access = () => {
        const data = {
            house_id: props.house_id,
            status: 1
        }
        edit_house(data).then(()=>{
            props.closeDetail(props.house_id)
        })
    }

  return (
    <div className='detail-ctn'>
        <div className='detail'>
            <div className='close'>
                <img src={close} onClick={closeDetail}></img>
            </div>
            <div className='detail-body'>
                <div className='item'>
                    <div className='detail-text' style={{marginTop:'20px'}}>标题:</div>
                    <div className='title'>{house.title}</div>
                </div>
                <div className='item'>
                    <div className='detail-text'>招租描述:</div>
                    <div className='content'>{house.content}</div>
                </div>
                <div className='item'>
                    <div className='detail-text'>图片:</div>
                    <div className='h-img'>
                        <Image.PreviewGroup>
                            { house.images.map((image)=>(
                                <Image width={200} height={200} style={{padding: '4px'}} src={'https://map.images.qiuyangli.com/'+image.id}></Image>
                                ))
                            }
                        </Image.PreviewGroup>
                    </div>
                </div>
                <div className='item'>
                    <div className='detail-text'>视频:</div>
                    <div className='h-video'>
                        { house.videos.map((video)=>(
                            <video width={320} height={240} controls style={{padding: '4px'}}>
                                <source src={'https://map.vedios.qiuyangli.com/'+video.id} type='video/mp4'></source>
                            </video>
                        ))}
                    </div>
                </div>
            </div>
            <div className='btn-area' hidden={props.hidden}>
                <Button type='primary' danger onClick={unAccess}>不通过</Button>
                <Button type='primary' onClick={access}>通过</Button>
            </div>
        </div>
    </div>
  )
}
