import { Card, List, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'

import './index.css'
import { get_all_house, del_house } from '../../services/server'


import HouseDetail from '../HouseDetail'

const { Meta } = Card

export default function Similar() {
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [isShowDetail, setIsShowDetail] = useState(false)
  const [houseID, setHouseID] = useState()

  const lookupHouse = (event) => {
    setIsShowDetail(true)
    setHouseID(event.target.dataset.house_id)
  }

  const closeDetail = () => {
    setIsShowDetail(false)
  }

  const deleteHouse = (event) => {
    Modal.confirm({
      title: '删除提示',
      content: '您确定要删除这套房子吗？',
      onOk:()=>{
        const house_id = event.target.dataset.house_id
        del_house({ house_id }).then(() => {
          get_all_house({ page }).then(res => {
            setData(res.houses)
            setTotal(res.total)
            message.success({
              content: '删除成功',
              duration: 1
            })
          })
        })
      }
    })
  }

  const dataIndexChange = (page) => {
    setPage(page)
    get_all_house({ page }).then(res => {
      setData(res.houses)
      setTotal(res.total)
    })
  }

  useEffect(()=>{
    get_all_house({page}).then(res=>{
      setData(res.houses)
      setTotal(res.total)
    })
  },[])
  return (
    <div>
          <List
            pagination={{position:'bottom', 
              total:total, 
              pageSize: 8, 
              onChange:dataIndexChange}}
            grid={{ gutter: 16, column: 4 }}
            dataSource={data}
            style={{margin: '12px'}}
            renderItem={(item) => (
              <List.Item>
                <Card
                  cover={<img alt='cover' src={'https://map.images.qiuyangli.com/'+item.cover} style={{height: '22vh', objectFit:'cover'}}/>}
                  actions={[<span style={{color:'blue'}} key="lookup" data-house_id={item.id} onClick={lookupHouse}>查看</span>, <span style={{color:'red'}} key="delete" data-house_id={item.id} onClick={deleteHouse}>删除</span>]}
                >
                  <Meta
                    title={item.title}
                    description={<div style={{fontSize:'18px', color: 'red'}}>{item.price + '元/月'}</div>}
                  />
                </Card>
              </List.Item>
            )}
          >
          </List> 
      {isShowDetail ? <HouseDetail house_id={houseID} closeDetail={closeDetail} hidden={true} />:''}
    </div>
  )
}
