import axios from "axios";
import { apiDomain } from "./api";


const instance = axios.create({
    baseURL: apiDomain,
    timeout: 5000
})

instance.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token')
        if(token){
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response)=>{
        return response.data
    },
    (error)=>{
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    window.location.href = '/login';
                    break;
                default:
                    console.error(error.response.data);
                    break;
            }
        } else {
            // 处理请求错误
            console.error('Error:', error);
        }

        // 返回Promise.reject结束请求，并传递错误信息
        return Promise.reject(error);
    }
)

export function get_houses(params){
    return instance.get('/process',{params})
}

export function get_house_detail(params){
    return instance.get('/process/detail', {params})
}

export function login_sys(data){
    return instance.post('/login', data)
}

export function edit_house(data){
    return instance.post('/house/edit',data)
}

export function pre_login(){
    return instance.get('/pre-login')
}

export function get_all_house(params){
    return instance.get('/house/similar', {params})
}

export function del_house(params){
    return instance.delete('/house/similar', {data:params})
}