import React from 'react'
import { Button, Input, Form, Checkbox, message } from 'antd'
import { UserOutlined, LockOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import JSEncrypt from 'jsencrypt'

import { login_sys, pre_login } from '../../services/server'

import './index.css'
import left from '../../assets/left.png'


export default function Login() {
    const navigate = useNavigate()
    const login = (values) => {
        pre_login().then(res=>{
            const pub_key = res.publick_key
            let encrypt = new JSEncrypt()
            encrypt.setPublicKey(pub_key)
            var password = encrypt.encrypt(values.password)
            values.password = password
            login_sys(values).then(res => {
                sessionStorage.setItem('token', res.token)
                navigate('/home')
            }).catch(err => {
                message.error(err.msg)
            })
        })
    }
  return (
    <div className='bg'>
        <div className='login-ctn'>
            <div>
                <img src={left} alt='logo'></img>
            </div>
            <div className='login'>
                <div className='logo-text'>海角合租地图后台管理</div>
                <Form
                    name="normal_login"
                    className='login-form'
                    initialValues={{ remember: true }}
                    onFinish={ login }
                >
                      <Form.Item
                          name="username"
                          rules={[{ required: true, message: '请输入账号！!' }]}
                      >
                          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" />
                      </Form.Item>
                      <Form.Item
                          name="password"
                          rules={[{ required: true, message: '请输入密码！' }]}
                      >
                          <Input
                              prefix={<LockOutlined className="site-form-item-icon" />}
                              type="password"
                              placeholder="密码"
                          />
                      </Form.Item>
                      <Form.Item>
                          <Form.Item valuePropName="checked" noStyle>
                              <Checkbox>记住账号</Checkbox>
                          </Form.Item>
                      </Form.Item>

                      <Form.Item>
                          <Button type="primary" htmlType="submit" className="login-form-button">
                              登录
                          </Button>
                      </Form.Item>
                </Form>
            </div>
        </div>
    </div>
  )
}
