import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { TeamOutlined, HomeOutlined, AuditOutlined } from '@ant-design/icons'
import { useRoutes, useNavigate, useLocation } from 'react-router-dom'

import logo from '../../assets/logo.png'
import Process from '../../components/Process'

import './index.css'
import House from '../../components/House'
import Admin from '../../components/Admin'
import Landlord from '../../components/Landlord'
import Similar from '../../components/Similar'


const { Sider, Content } = Layout

const items = [
    {
        label: '人员管理',
        key: '/staff',
        icon: <TeamOutlined/>,
        children:[
            {
                label:'管理员',
                key: '/home/staff/admin'
            },
            {
                label: '房东们',
                key: '/home/staff/landlord'
            }
        ]
    },
    {
        label: '房源管理',
        icon: <HomeOutlined/>,
        key: '/houses',
        children:[
            {
                label: '全部房源',
                key: '/home/houses/all',
            },
            {
                label: '审核房源',
                key: '/home/houses/process'

            },
            {
                label: '相似房源',
                key: '/home/houses/similar'
            }
        ]
    },
    /*{
        label: '审核管理',
        key: '/auditing',
        icon: <AuditOutlined/>,
        children: [
            {
                label: '房源审核',
                key: '/auditing/house'
            },
            {
                label: ''
            }
        ]
    }*/
]

const routers = [
    {
        path: '/houses/process',
        element: <Process />
    },
    {
        path:'/houses/all',
        element: <House />
    },
    {
        path: '/staff/admin',
        element: <Admin />
    },
    {
        path: '/staff/landlord',
        element: <Landlord />
    },
    {
        path: '/houses/similar',
        element: <Similar/>
    }
]

export default function Home() {
    const elements = useRoutes(routers)
    const navigate = useNavigate()
    const location = useLocation()
    const [openKeys, setOpenKeys] = useState([])

    const handleOpenChange = (openKeys) => {
        setOpenKeys(openKeys)
    }
    const onClick = (item) => {
        navigate(item.key)
    }
  return (
    <div>
          <Layout style={{ minHeight: '100vh' }}>
              <Sider>
                  <div>
                      <img src={logo} className='logo_img' alt='logo'></img>
                    <div className='text'>海角合租</div>
                  </div>
                  <Menu theme="dark" 
                    defaultSelectedKeys={[location.pathname]} 
                    openKeys={ openKeys }
                    onOpenChange={ handleOpenChange }
                    mode="inline" 
                    items={items} 
                    onClick={onClick}>
                </Menu>
              </Sider>
              <Content>
                {elements}
              </Content>
        </Layout>
    </div>
  )
}
