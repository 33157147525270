import { Route, Routes, Navigate } from 'react-router-dom'

import Home from '../pages/Home'
import Login from '../pages/Login'


export default function Routers() {
    return (
        <Routes>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/home/*' element={<Home />}></Route>
            <Route path='/' element={<Navigate to='/login'></Navigate>} exact></Route>
        </Routes>
    )
}