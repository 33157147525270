var apiDomain

if (process.env.NODE_ENV === 'production') {
    apiDomain = 'https://api.map.qiuyangli.com/admin'
}
else {
    apiDomain = 'http://127.0.0.1:8000/admin'
}

export {
    apiDomain
}
